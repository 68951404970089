import apiCall from "../core/apiCall";

export default {
    state: {
        menus:[],
    },
    getters: {
        menus: state => {
            return state.menus;
        },
        menusTop: state => {
            return state.menus.filter(item=>parseInt(item.align)===0 && item.public_sub_menus.length >0);
        },
        menusLeft: state => {
            return state.menus.filter(item=>parseInt(item.align)===1 && item.public_sub_menus.length > 0);
        },
        menusRight: state => {
            return state.menus.filter(item=>parseInt(item.align)===2 && item.public_sub_menus.length > 0);
        },
    },
    mutations: {
        SET_ALL_MENU(state, menus) {
            state.menus=menus;
        },
    },
    actions: {
        async GET_ALL_MENU(context){

            const specificDate = new Date('2025-03-10');
            const currentDate = new Date();
            if (currentDate < specificDate) {
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/public/site/menu/sub_menus')
                .then((response) => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_MENU',response.data );
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
            }
        }
    },
}
